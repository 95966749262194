@import "./Variables.scss";

@mixin roundedBtn($bgColor, $textColor) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bgColor;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  color: $textColor;
  border: 0;
}

.modalContainer {
  .scrollable-content {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .product-info-block {
    .back-btn {
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .delete-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      @include roundedBtn(#f1f1f1, red);
      &:focus {
        outline: 0;
      }
      &:active {
        opacity: 0.5;
      }
    }

    .productName {
      padding: 0 20px;
      font-size: 35px;
      margin: 20px 0px;
    }

    .product-description {
      padding: 0 20px;
    }

    .product-img {
      height: 300px;
      width: 100%;
      object-fit: cover;
    }

    .product-price {
      margin: 20px 0px;
      font-size: 20px;
      text-align: center;
    }
  }

  .content-block {
    padding: 0 20px;
    .section-header {
      padding: 15px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .rubricItem {
      display: flex;
      flex-direction: row;
      margin: 0 !important;
      padding: 5px 0px;
    }

    .rubric-name {
      flex: 3;
      font-size: 18px;
      padding-left: 5px;
    }
    .rubric-price {
      flex: 1;
      text-align: right;
    }
  }

  .quantityBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 120px;
    .quantityBtn {
      @include roundedBtn($mainColor, white);
      font-size: 30px;
    }
    .quantityBtn:active {
      opacity: 0.5;
    }
    .quantityBtn:focus {
      outline: 0;
    }
    .totalQty {
      width: 100px;
      font-size: 30px;
      padding: 0 20px;
      text-align: center;
      background-color: white;
      border: none;
    }
  }

  .modalFooter {
    background-color: white;
    margin: auto auto !important;
    max-width: 300px;
    @extend .footer-block;
    bottom: 20px;
    border-radius: 60px;
    .confirm-btn {
      justify-content: space-around;
      border-radius: 50px;
    }
  }

  .rubric-quantity-container {
    display: inline-block;
    margin-left: 10px;
    font-size: 1.1em;
    & span {
      margin: 0 5px;
    }
    .rubric-quantity-control {
      $size: 25px;

      display: inline-block;
      background-color: var(--website-color);
      border-radius: $size;
      width: $size;
      height: $size;
      line-height: $size;
      color: white;
      text-align: center;
      cursor: pointer;
    }

    .rubric-quantity-number {
      font-weight: bold;
    }
  }
}

@media (min-width: 580px) {
  .modalContainer {
    .product-info-block {
      .product-img {
        height: 480px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
