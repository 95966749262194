@import "./Variables.scss";

#checkout.container {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: auto !important;
  min-height: 100vh;
}

#checkout {
  .cart-my-order {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    background-color: white;
  }

  .cart-title {
    font-size: 18px;
    font-weight: bold;
    color: black;
    margin-top: 22px;
    margin-bottom: 10px;
  }

  .cart-product-container {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 10px;
    background-color: white;
    .form-row {
      padding: 5px 0px;
    }

    .custom-radio {
      margin: 10px 0px;
    }
    .main-color {
      color: $mainColor
    }
  }

  .cart-row {
    width: 80%;
    display: flex;
    align-items: center;
    position: relative;

    &.crossed:after {
      position: absolute;
      left: 0;
      top: 50%;
      height: 1px;
      background: #000;
      content: "";
      width: 100%;
      display: block;
    }
  }

  .cart-total-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    padding: 10px 20px 0;
  }

  .comment-block {
    background-color: white;
    padding: 0 20px 20px;
    margin-top: 5px;
    margin-bottom: 100px;
  }

  .step {
    display: inline-block;
    border: 2px solid $mainColor;
    border-radius: 30px;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    text-align: center;
    color: $mainColor
    // height: 20px;
  }

  .footer {
    position: fixed;
    background-color: white;
    padding: 12px;
    z-index: 1000;
    &:hover {
      text-decoration: none;
    }
    @extend .footer-block;
    .confirm-btn {
      justify-content: space-around;
    }
    button {
      width: 100%;
      border: 0;
      padding: 0;
    }
  }

  .custom-map-modal {
    .modal-dialog {
      max-width: 600px;
      height: 100%;
      padding: 0;
      margin: auto auto !important;
    }
    .modal-content {
      height: auto;
      min-height: 100%;
      border-radius: 0;
      border: 0;
    }
  }

  @media (min-width: 580px) {
    .form {
      display: flex;
      flex-direction: row;
    }
    .left-block {
      flex: 2 0;
      margin: 20px 0 20px 20px;
    }

    .cart {
      flex: 1 0;
      margin: 20px;
      min-width: 320px;
      max-height: 730px;
      background-color: white;
      .cart-product-container {
        height: 440px;
        overflow: auto;
      }
    }

    .cart-my-order {
      height: 60px;
      width: 100%;
      margin-top: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background-color: white;
      .cart-title {
        font-size: 20px;
        color: black;
      }
    }

    .cart-total-container {
      margin-top: 0;
      margin-bottom: 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-color: white;
      padding-left: 5%;
      padding-right: 5%;
    }

    .comment-block {
      background-color: white;
      margin-top: 5px;
      padding: 0 20px 20px;
    }

    .footer {
      position: relative;
      left: 0;
      right: 0;
      border: 0;
      background-color: white;
      padding: 12px;
      z-index: 10;
    }
  }

  // Bootstrap overrides
  input.form-control, textarea.form-control, select.form-control {
    &:focus {
      box-shadow: none;
      border-bottom: 2px solid black;
    }

    background-color: #f6f6f6;
    border: none;
    border-bottom: 2px solid #e2e2e2;
    border-radius: 0;
  }

  // radio button
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: black;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: black;
    background-color: black;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .payment-option-container {
    display: flex;
    flex-wrap: wrap;

    .payment-option-button {
      position: relative;
      cursor: pointer;
      margin: 10px 5px;
      border-radius: 0.2rem;
      padding: 1.2rem 0;
      width: 120px;
      background-color: rgb(240, 240, 240);
      text-align: center;
      border: 2px solid lightgray;
      &.selected {
        border: 2px solid var(--website-color);
      }
    }
  }

  .coupon-button {
    background-color: $mainColor;
    color: white;
    height: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}